import { render, staticRenderFns } from "./ReportsSubmenuHeader.vue?vue&type=template&id=34155144&scoped=true&"
import script from "./ReportsSubmenuHeader.vue?vue&type=script&lang=js&"
export * from "./ReportsSubmenuHeader.vue?vue&type=script&lang=js&"
import style0 from "./ReportsSubmenuHeader.vue?vue&type=style&index=0&id=34155144&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34155144",
  null
  
)

export default component.exports